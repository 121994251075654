import React, { useState, useEffect } from 'react'
import { Button } from '@ui'
import axios from 'axios'

import { ENGINE } from '@env'

import Loader from '@blocks/Loader'
import ContainerNav from '@blocks/ContainerNav'

import './style.scss'

const ChessTop = ({ data }) => {
    return  <div className='chess-top chess-row'>
                {data.map((pos) => (
                    <div className='chess-cell' style={{width: `calc(100%/${data.length})`}}>{pos}</div>
                ))}
            </div>
}

const Chess = ({ data, idx, setItem, names }) => {
    return  <div className='chess-row'>
                {data.map((cell, i) => {
                    return  <div className={'chess-cell'+(i === idx ? ' self' : '')} style={{width: `calc(100%/${data.length})`}}>
                                {cell.map(m => (
                                    <span onClick={() => setItem({scope: 'match', _id: m._id, name: `${names[idx]}🆚${names[i]}`})}>{m.score}</span>
                                ))}
                            </div>
                })}
            </div>
}

const Table = ({ data, mode, setItem }) => {
    return  <div className={'table '+mode}>
                <div className='table-row head'>
                    <div></div>
                    <div className='team'></div>
                    <div className='team-compact'></div>
                    {mode !== 'chess' ? [
                        <div>И</div>,
                        <div>В</div>,
                        <div>Н</div>,
                        <div>П</div>
                    ] : (
                        <ChessTop data={data.map((row, i) => i+1)} />
                    )}
                    <div className='double'>РГ</div>
                    <div>О</div>
                </div>
                {data.map((row, i) => (
                    <div className='table-row' key={row.team._id} onClick={() => setItem({scope: 'team', _id: row.team._id, name: row.team.name})}>
                        <div>{i+1}</div>
                        <div className='team'>{row.team.name}</div>
                        <div className='team-compact'>{row.team.name.slice(0, 3).toUpperCase()}</div>
                        {mode !== 'chess' ? [
                            <div>{row.pld}</div>,
                            <div>{row.w}</div>,
                            <div>{row.d}</div>,
                            <div>{row.l}</div>
                        ] : (
                            <Chess data={row.chess} idx={i} setItem={setItem} names={data.map(row => row.team.name)} />
                        )}
                        <div className='double'>{row.gs} - {row.gc}</div>
                        <div>{row.pts}</div>
                    </div>
                ))}
            </div>
}

const Stage = ({ data, mode, setItem }) => {
    switch (data.type) {
        case 'groups':
            return  <div className='stage'>
                        <div className='stage-title'>{data.title}</div>
                        {data.groups.map(group => (
                            <div className='stage-group' key={group._id}>
                                <div className='stage-group_title'>
                                    <Button
                                        label={group.name}
                                        variant='base'
                                        size='small'
                                    />
                                </div>
                                <Table data={group.standings} mode={mode} setItem={setItem} />
                            </div>
                        ))}
                    </div>
            break
        case 'round':
            return  <div className='stage'>
                        <Table data={data.standings} mode={mode} setItem={setItem} />
                    </div>
            break
        case 'playoff':
            if(data.content && data.content.banners) {
                return  <div className='playoff-grid-banner'>
                            <img className='mobile-banner' src={data.content.banners.mobile} />
                            <img className='desktop-banner' src={data.content.banners.desktop} />
                        </div>
            } else {
                return null
            }
            break
        default:
            return null
    }
}

const Standings = ({ tournament, setItem }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState('compact')

    useEffect(() => {
        if(tournament) {
            setLoading(true)
            axios.get(`${ENGINE}standings/${tournament}`)
                .then(sts => {
                    setData(sts.data)
                    setLoading(false)
                })
        }
    }, [tournament])

    return  <div className='standings'>
                <Loader active={loading} />
                <ContainerNav
                    scope='tables'
                    isNested={true}
                    content={mode}
                    setContent={setMode}
                />
                {data.map(stage => (
                    <Stage
                        setItem={setItem}
                        key={stage._id}
                        data={stage}
                        mode={mode}
                    />
                ))}
            </div>
}

export default Standings
