import React from 'react'

import { Button } from '@ui'
import MatchCard from '@blocks/MatchCard'
import NonIdealState from '@blocks/NonIdealState'

const Matches = ({ list, setItem, loadingAll, setLoadingAll, loadedAll, recent }) => {
    const unassignedEdge = !recent ? list.findIndex(m => !m.date && !m.time) : -1

    return  <div>
                {list.length ? list.map((match, idx) => (
                    <MatchCard
                        key={match._id}
                        match={match}
                        setItem={setItem}
                        fromTeam={true}
                        showUnassugnedPrepend={!recent && idx === unassignedEdge}
                    />
                )) : (
                    <NonIdealState
                        icon={recent ? 'sports_soccer' : 'calendar_today'}
                        title=''
                        description={recent ? 'нет результатов за последнюю неделю' : 'на ближайшую неделю матчи не запланированы'}
                    />
                )}
                {!loadedAll ? <Button
                    className='content-load-more'
                    onClick={() => setLoadingAll(true)}
                    variant='base'
                >{loadingAll ? [
                    <span className='icons spinned' style={{marginRight: 6}}>sports_soccer</span>,
                    'загрузка'
                ] : 'Показать все'}</Button> : null}
            </div>
}

export default Matches
