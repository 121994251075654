import React, { useState, useEffect } from 'react'

import { ENGINE } from '@env'
import axios from 'axios'
import ContainerNav from '@blocks/ContainerNav'
import CustomScrollbars from 'react-custom-scrollbars-2'

import Loader from '@blocks/Loader'

import Events from './Events'
import Rosters from './Rosters'
import Info from './Info'
import Broadcast from './Broadcast'

import './style.scss'

const extractEmblem = obj => {
    try {
        return obj.emblem ? obj.emblem : obj.club.emblem ? obj.club.emblem : (obj.club.origin && obj.club.origin.emblem) ? obj.club.origin.emblem.replace('26x26', '150x150') : require('@img/emblem_light.png')
    } catch (e) {
        return require('@img/emblem_light.png')
    }
}

const contentTypes = {
    events: Events,
    rosters: Rosters,
    info: Info,
    broadcast: Broadcast
}

const Match = ({ _id, isMobile, sponsorship, setItem }) => {
    const [data, setData] = useState(null)
    const [content, setContent] = useState(data && data.scores ? 'events' : 'info')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(_id) {
            axios.get(`${ENGINE}matchinfo/${_id}?full=true`)
                .then(info => {
                    setData(info.data)
                    if(info.data.scores) {
                        setContent('events')
                    } else if(info.data.broadcast) {
                        setContent('broadcast')
                    }

                    setTimeout(() => {
                        setLoading(false)
                    }, 1500)
                })
        }
    }, [_id])

    const Specified = contentTypes[content] || null

    return  data ? <div className='match-item'>
                <Loader active={loading} />
                <div className='match-top'>
                    <div className='team' onClick={e => {
                        setItem({scope: 'team', _id: data.home._id, name: data.home.name});
                    }}>
                        <div className='emb'>
                            <img src={extractEmblem(data.home)} />
                        </div>
                        <div className='name'>{data.home.name}</div>
                    </div>
                    <div className='info'>
                        {data.scores ? (
                            <div className='scores'>
                                <div>{data.scores.full.home} - {data.scores.full.away}</div>
                            </div>
                        ) : (
                            <div className='datetime'>
                                <div className='date'>{data.matchday.date}</div>
                                <div className='time'>{data.matchday.time}</div>
                                <div className='pitch'>{data.matchday.pitch}</div>
                            </div>
                        )}
                    </div>
                    <div className='team' onClick={e => {
                        setItem({scope: 'team', _id: data.away._id, name: data.away.name});
                    }}>
                        <div className='emb'>
                            <img src={extractEmblem(data.away)} />
                        </div>
                        <div className='name'>{data.away.name}</div>
                    </div>
                </div>

                <ContainerNav
                    scope='match'
                    filter={!data || !data.broadcast ? 'broadcast' : null}
                    content={content}
                    setContent={setContent}
                />

                {Specified ? (
                    <CustomScrollbars autoHeight autoHeightMin={isMobile ? 'calc(100vh - 280px)' : sponsorship ? 'calc(100vh - 430px)' : 'calc(100vh - 400px)'}>
                        {data[content] || content === 'info' ? <Specified
                            data={data[content] || data}
                            homeId={data.home._id}
                            awayId={data.away._id}
                            setItem={setItem}
                        /> : null}
                    </CustomScrollbars>
                ) : null}
            </div> : null
}

export default Match
