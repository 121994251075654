import React from 'react'

import './style.scss'

const NonIdealState = ({ icon, title, description }) => {
    return  <div className='non-ideal'>
                <i className='icons'>{icon}</i>
                <h5>{title}</h5>
                <div>{description}</div>
            </div>
}

export default NonIdealState
