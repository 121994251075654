import React, { useState, useEffect } from 'react'

import { ENGINE } from '@env'
import axios from 'axios'
import moment from 'moment'
import CustomScrollbars from 'react-custom-scrollbars-2'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import './style.scss'

import Loader from '@blocks/Loader'
import MatchCard from '@blocks/MatchCard'
const values = ['pld', 'mvp', 'goal', 'assist', 'yc', 'rc']

const positions = {
    'ВР': 'GK',
    'ЗЩ': 'DF',
    'ПЗ': 'MF',
    'НП': 'FW'
}

const formatAge = date => {
    if(!date) {
        return 'возраст не указан'
    } else {
        const age = moment().diff(moment(date, 'DD-MM-YYYY'), 'years')
        const str = age.toString()
        const lch = str[str.length - 1]
        return str+' '+(lch === '1' ? 'год' : ['2', '3', '4'].includes(lch) ? 'года' : 'лет')
    }
}

const Player = ({ _id, isMobile, setItem }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(_id) {
            axios.get(`${ENGINE}player/${_id}`)
                .then(resp => {
                    setLoading(false)
                    setData(resp.data)
                })
        }
    }, [_id])

    return  <div className='player'>
                <Loader active={loading} />
                {data ? <div className='player-top'>
                    <div className='player-info'>
                        <div className='name'>{data.name} {data.surname}</div>
                        <div className='meta'>{formatAge(data.birthday)}</div>

                        <div className='stats'>
                            <span className='primary-label'>MVP матча</span>
                            <div className='primary'>
                                <i className='icons'>close</i>
                                <span>{data.total.mvp}</span>
                            </div>

                            <div className='rest'>
                                {data.position && positions[data.position] ? (
                                    <div className='position'>{positions[data.position]}</div>
                                ) : null}
                                <div className='row'>
                                    <div>всего матчей</div>
                                    <div>{data.total.pld}</div>
                                </div>
                                <div className='row'>
                                    <div>голы</div>
                                    <div>{data.total.goal}</div>
                                </div>
                                <div className='row'>
                                    <div>ассисты</div>
                                    <div>{data.total.assist}</div>
                                </div>
                                <div className='row'>
                                    <div>ЖК</div>
                                    <div>{data.total.yc}</div>
                                </div>
                                <div className='row'>
                                    <div>КК</div>
                                    <div>{data.total.rc}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'player-photo '+(!data.avatarUrl ? 'holder' : '')}>
                        <img src={data.avatarUrl || require('@img/sihlouette.png')} />
                        {/*<img src='https://amateum.fra1.cdn.digitaloceanspaces.com/storage/85135fa1-b938-4d50-8743-121e7dcc6e19.png' />*/}
                    </div>
                </div> : null}
                {data ? (
                    <CustomScrollbars autoHeight autoHeightMin={isMobile ? '58vh' : '50vh'}>
                    <div className='player-content'>
                        <div className='player-section'>
                            <h4 className='section-title'>Сезоны</h4>
                            <div className='section-grid'>
                                <Splide
                                    options={{
                                        pagination: false,
                                        arrows: false,
                                        fixedWidth: isMobile ? '260px' : 'calc(50% - .5em)',
                                        start: 0,
                                        gap: '1em',
                                        interval: 4500,
                                        perMove: 1
                                    }}
                                >
                                {data.seasons.map(s => (
                                    <SplideSlide>
                                        <div className='section-grid-card with-image'>
                                            <div className='section-grid-card_img'>
                                                <img src={s.team.emblem} />
                                            </div>

                                            <div className='section-grid-card_content'>
                                                <div className='section-grid-card_title'>{s.tournament.name}</div>
                                                <div className='section-grid-card_tag'>{s.tournament.league}, {s.tournament.season}</div>
                                                <div className='season-stats'>
                                                {s.stats ? values.map(v => (
                                                    <div className={`season-stats-value ${v}`}>
                                                        <span>{s.stats[v]}</span>
                                                    </div>
                                                )) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                ))}
                                </Splide>
                            </div>
                        </div>

                        <div className='player-section'>
                            <h4 className='section-title nomar'>Матчи</h4>
                            {data.matches.map(m => (
                                <MatchCard match={m} setItem={setItem} />
                            ))}
                        </div>
                    </div>
                    </CustomScrollbars>
                ) : null}
            </div>
}

export default Player
