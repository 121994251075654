import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { ENGINE } from '@env'
import CustomScrollbars from 'react-custom-scrollbars-2'

import './style.scss'

import Loader from '@blocks/Loader'
import ContainerNav from '@blocks/ContainerNav'

import Matches from './Matches'
import Squads from './Squads'

const extractEmblem = obj => {
    try {
        return obj.emblem ? obj.emblem : obj.club.emblem ? obj.club.emblem : (obj.club.origin && obj.club.origin.emblem) ? obj.club.origin.emblem.replace('26x26', '150x150') : require('@img/emblem_light.png')
    } catch (e) {
        return require('@img/emblem_light.png')
    }
}

const Team = ({_id, isMobile, sponsorship, setItem }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [content, setContent] = useState('recent')
    const [loadingAll, setLoadingAll] = useState(false)
    const [loadedAll, setLoadedAll] = useState(false)

    useEffect(() => {
        if(loadingAll) {
            axios.get(`${ENGINE}team/${_id}?full=true`)
                .then(resp => {
                    setData(resp.data)
                    setLoadingAll(false)
                    setLoadedAll(true)
                })
        }
    }, [loadingAll])

    useEffect(() => {
        if(_id) {
            setData(null)
            setLoading(true)
            axios.get(`${ENGINE}team/${_id}`)
                .then(resp => {
                    setData(resp.data)
                    setLoading(false)
                    setLoadedAll(false)
                })
        }
    }, [_id])

    const mapSquadStats = (squad, stats) => {
        console.log('Mapping', squad, stats)
        const mapped = {
            ...squad,
            list: squad.list.map(row => {
                return {...row, stats: stats[row._id.toString()]}
            })
        }

        return mapped
    }

    const mapStats = stats => {
        const upd = data.squads.map(sqd => {
            return stats[sqd._id] ? mapSquadStats(sqd, stats[sqd._id]) : {...sqd}
        })

        setData({...data, squads: upd})
    }

    const renderInner = () => {
        if(data) {
            switch (content) {
                case 'recent':
                    return <Matches list={data.recent} recent={true} setItem={setItem} loadingAll={loadingAll} setLoadingAll={setLoadingAll} loadedAll={loadedAll} />
                    break
                case 'upcoming':
                    return <Matches list={data.upcoming} setItem={setItem} loadingAll={loadingAll} setLoadingAll={setLoadingAll} loadedAll={loadedAll} />
                    break
                case 'squads':
                    return  <Squads
                                data={data.squads}
                                mapStats={mapStats}
                                setItem={setItem}
                            />
                    break
            }
        } else {
            return null
        }
    }

    return  [
                <div className={'team-top'+(data && data.info ? ' shown' : '')}>
                    {data && data.info ? [
                        <div className='emblem'>
                            <img src={extractEmblem(data.info)} />
                        </div>,
                        <div className='info'>
                            {data.info.form ? (
                                <div className='form'>
                                    {data.info.form.map(o => <span className={o}></span>)}
                                </div>
                            ) : null}
                            <div className='name'>{data.info.name}</div>
                            <div className='settlement'>{data.info.settlement ? data.info.settlement.name : ''}</div>
                        </div>
                    ] : null}
                </div>,
                <div className='team'>
                    <Loader active={loading} />
                    <ContainerNav
                        scope='team'
                        content={content}
                        setContent={setContent}
                    />
                    <div className='team-content'>
                        <CustomScrollbars autoHeight autoHeightMin={isMobile ? sponsorship ? 'calc(100vh - (170px + 1em))' : 'calc(100vh - (150px + 1em))' : sponsorship ? 'calc(100vh - 310px)' : 'calc(100vh - 270px)'} autoHide>
                            {renderInner()}
                        </CustomScrollbars>
                    </div>
                </div>
            ]
}

export default Team
