import React from 'react'

import './style.scss'

const randomizePromo = arr => {
    const qty = 3
    let shuffled = arr
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)

    return shuffled.slice(0, qty)
}

const Promo = ({ promoMode, promo }) => {
    const isGrid = promoMode.includes('grid')
    const pull = promoMode.includes('random') ? randomizePromo(promo) : [...promo]

    return  <div className={'promo-block '+(isGrid ? 'grid' : promoMode)+(isGrid ? ' g'+pull.length : '')}>
                {isGrid ? pull.map(i => (
                    <div className='promo-item' onClick={() => window.open(i.link)}>
                        <img src={i.banner} />
                    </div>
                )) : null}
            </div>
}

export default Promo
