module.exports = {
    PWA_VERSION: '1.8.8',
    //ENGINE: 'https://proxy.amateum.com/client/',
    ENGINE: process.env.NODE_ENV === 'production' ? 'https://proxy.amateum.com/client/' : 'http://localhost:3123/client/',
    ORIGIN: 'rfs-sochi.ru'
    //ORIGIN: 'lflivanovo.ru'
    //ORIGIN: 'stat.football63.com'
    //ORIGIN: 'ffgt.ru'
    //ORIGIN: 'lfl-cherepovets.ru'
    //ORIGIN: 'kidsfootboll.ru'
}
