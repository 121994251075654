import React, { useState } from 'react'

import moment from 'moment'

import './style.scss'

const extractEmblem = obj => {
    try {
        return obj.emblem ? obj.emblem : obj.club.emblem ? obj.club.emblem : (obj.club.origin && obj.club.origin.emblem) ? obj.club.origin.emblem.replace('26x26', '150x150') : require('@img/emblem_light.png')
    } catch (e) {
        return require('@img/emblem_light.png')
    }
}

const MatchCard = ({ match, setItem, fromTeam, isGroupedByDate, showUnassugnedPrepend }) => {
    return  [
                match.date && fromTeam && !match.scores ? (
                    <div className='match-prepend-date'>
                        <div className='calendar-section_top'>{moment(match.date, 'YY-MM-DD').format('dddd, D MMMM')}</div>
                    </div>
                ) : showUnassugnedPrepend ? (
                    <div className='match-prepend-date'>
                        <div className='calendar-section_top'>дата не назначена</div>
                    </div>
                ) : null,
                <div className='calendar-section_match' key={match._id} onClick={() => setItem({scope: 'match', _id: match._id, name: match.home.name+' 🆚 '+match.away.name})}>
                    <div className='location'>
                        {match.location ? (<div>{match.location.name}</div>) : null}
                    </div>

                    <div className='teams'>
                        <div className='team' onClick={e => {
                            e.stopPropagation();
                            setItem({scope: 'team', _id: match.home._id, name: match.home.name});
                        }}>
                            <i className='emb'>
                                <img src={extractEmblem(match.home)} />
                            </i>
                            <span>{match.home.name}</span>
                        </div>
                        <div className='vs'>
                            <span>{match.scores ? `${match.scores.full.home}:${match.scores.full.away}` : (isGroupedByDate || fromTeam) ? (match.time || '- : -') : '- : -'}</span>
                        </div>
                        <div className='team' onClick={e => {
                            e.stopPropagation();
                            setItem({scope: 'team', _id: match.away._id, name: match.away.name});
                        }}>
                            <i className='emb'>
                                <img src={extractEmblem(match.away)} />
                            </i>
                            <span>{match.away.name}</span>
                        </div>
                    </div>
                    <div className='meta'>

                    </div>
                </div>
            ]
}

export default MatchCard
