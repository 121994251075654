import React, { useState, useEffect } from 'react'

import { Button, ButtonMenu, MenuItem } from '@ui'
import { ENGINE } from '@env'

import axios from 'axios'

const values = ['pld', 'mvp', 'goal', 'assist', 'yc', 'rc']

const Squads = ({ data, mapStats, setItem }) => {
    const [shown, setShown] = useState(null)
    const [loadedStats, setLoadedStats] = useState(false)

    const loadStats = async (ids) => {
        setLoadedStats(true)
        const upd = {}
        for(let squadId of ids) {
            const _stats = await axios.get(`${ENGINE}squadstats/${squadId}`)
            if(_stats.data) {
                upd[squadId] = _stats.data
            }
        }

        mapStats(upd)
    }

    useEffect(() => {
        if(data) {
            setShown(data[0])
            if(!loadedStats) {
                loadStats(data.map(sqd => sqd._id))
            }
        }
    }, [data])

    return  <div className='squad-list'>
                {shown ? [
                    <div className='squad-select'>
                        <ButtonMenu
                            label={shown.tournament.name}
                            buttonSize='small'
                            buttonVariant='brand'
                            icon={<i className='icons'>expand_circle_down</i>}
                            iconPosition='right'
                        >
                            {data.map(sqd => (
                                <MenuItem label={sqd.tournament.name} onClick={() => setShown(sqd)} />
                            ))}
                        </ButtonMenu>
                    </div>,
                    <div className='list'>
                        {shown.list.map(pl => (
                            <div className='squad-player' onClick={() => setItem({scope: 'player', _id: pl._id, name: pl.name+' '+pl.surname})}>
                                <div className='num'>{pl.number || ''}</div>
                                <div className='name'>{pl.name} {pl.surname}</div>
                                <div className='squad-stats'>
                                    {pl.stats ? values.map(v => (
                                        <div className={`squad-stats-value ${v}`}>
                                            {pl.stats[v] ? (
                                                <span>{pl.stats[v]}</span>
                                            ) : null}
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                ] : null}
            </div>
}

export default Squads
