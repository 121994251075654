import React, { useState, useEffect } from 'react'

import { Button } from '@ui'

import './style.scss'

const Install = ({ prompt, onOutcome, manifest, isIos }) => {
    const [className, setClassName] = useState(null)

    useEffect(() => {
        setTimeout(() => {
            setClassName('shown')
        }, 1000)
    }, [])

    const dismiss = () => {
        setClassName(null)
        setTimeout(() => {
            window.deferredPrompt = null
            onOutcome()
        }, 1500)
    }

    const showNativePrompt = async () => {
        const promptEvent = window.deferredPrompt
        if(!promptEvent) {
            return
        }

        promptEvent.prompt()
        const result = await promptEvent.userChoice
        console.log('👍', 'userChoice', result)
        window.deferredPrompt = null
        onOutcome()
    }

    return  <div className={`install ${className} ${isIos ? 'ios' : ''}`}>
                <img src={manifest.icons[0].src} />
                <h5>быстрая установка приложения {manifest.name} на Ваш смартфон</h5>
                <div>
                    <span><i className='icons'>check_circle</i>быстрый доступ к статистике и событиям</span>
                    <span><i className='icons'>check_circle</i>избранные команды, игроки и турниры</span>
                    <span><i className='icons'>check_circle</i>важные уведомления</span>
                </div>
                {isIos ? [
                    <Button variant='base' onClick={() => dismiss()} size='small'>закрыть</Button>,
                    <Button variant='brand' shaded>Нажмите иконку <i className='icons'>ios_share</i>, а затем «Добавить на домашний экран»</Button>
                ] : [
                        <Button variant='brand' onClick={() => showNativePrompt()} shaded>Установить сейчас</Button>,
                        <Button variant='base' onClick={() => dismiss()} size='small'>закрыть</Button>
                ]}
            </div>
}

export default Install
