import React from 'react'

const roles = {
    referee: {label: 'Арбитр', icon: 'sports'},
    media: {label: 'Фото', icon: 'photo_camera'},
    medicine: {label: 'Медик', icon: 'medical_services'},
    commentator: {label: 'Комментатор', icon: 'mic'},
    operator: {label: 'Оператор', icon: 'camera'}
}

const Info = ({ data }) => {
    const disquals = (data.home.disqualifiedFull && data.home.disqualifiedFull.length) || (data.away.disqualifiedFull && data.away.disqualifiedFull.length) ? [
        ...data.home.disqualifiedFull.map(p => ({...p, team: data.home.name})),
        ...data.away.disqualifiedFull.map(p => ({...p, team: data.away.name}))
    ] : null

    return  <div className='content-info'>
                {data.scores && data.matchday ? (
                    <div>{data.matchday.date}, {data.matchday.time}{data.matchday.pitch ? ', '+data.matchday.pitch : ''}</div>
                ) : null}
                <div>{data.matchday.division}</div>
                <div>{data.matchday.matchday}</div>
                {data.matchday.employees && data.matchday.employees.length ? (
                    <div className='section'>
                        <div className='section-title'>Назначения:</div>
                        <div className='section-grid section-cards-grid'>
                        {data.matchday.employees.map(e => (
                            <div className='section-grid-card'>
                                <div className='role'>
                                    <i className='icons'>{roles[e.role || e.roles[0]].icon}</i>
                                </div>
                                <div className='person'>
                                    <div>{roles[e.role || e.roles[0]].label.toLowerCase()}</div>
                                    <div>{e.name} {e.surname}</div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                ) : null}
                {disquals ? (
                    <div className='section'>
                        <div className='section-title'>Дисквалифицированы:</div>
                        <div className='section-grid section-cards-grid'>
                        {disquals.map(d => (
                            <div className='section-grid-card'>
                                {/*<div className='role'>
                                    <i className='icons'>{roles[e.role].icon}</i>
                                </div>*/}
                                <div className='person'>
                                    <div>{d.team}</div>
                                    <div>{d.player.name} {d.player.surname}</div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                ) : null}
            </div>
}

export default Info
