import React, { useState, useEffect } from 'react'

import './style.scss'

const Loader = ({ active }) => {
    return  <div className={'loader'+(active ? ' active' : '')}>
                <span className='icons'>sports_soccer</span>
                <div>загрузка...</div>
            </div>
}

export default Loader
