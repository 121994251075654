import React, { useState, useEffect, useRef } from 'react'
import { Application } from '@ui'
import CustomScrollbars from 'react-custom-scrollbars-2'

import { Helmet } from 'react-helmet'

import { ENGINE, ORIGIN } from '@env'
import axios from 'axios'

import 'moment/locale/ru'
import '@styles/app.scss'
//import '@styles/theme.scss'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

import { Button, ButtonMenu, MenuItem } from '@ui'

import { YMInitializer } from 'react-yandex-metrika'
import bridge from '@vkontakte/vk-bridge'

import Pills from '@blocks/Pills'
import ContainerNav from '@blocks/ContainerNav'
import Install from '@blocks/Install'
import Promo from '@blocks/Promo'

import Calendar from './containers/Calendar'
import Standings from './containers/Standings'
import Rankings from './containers/Rankings'
import Match from './containers/Match'
import Team from './containers/Team'
import Player from './containers/Player'

import UAParser from 'ua-parser-js'
const uaparser = new UAParser()

if(process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common['Debug-Origin'] = ORIGIN
}

const theme = {
    rainbow: {
        palette: {
            brand: '#891529'
        }
    }
}

const contentTypes = {
    calendar: {
        component: Calendar,
        props: {}
    },
    results: {
        component: Calendar,
        props: {
            recent: true
        }
    },
    standings: {
        component: Standings,
        props: {}
    },
    rankings: {
        component: Rankings,
        props: {}
    }
}

const itemTypes = {
    match: Match,
    team: Team,
    player: Player
}

const renderStyleStr = input => {
    let str = '.app {'
    str += Object.entries(input).filter(e => !e[0].includes('className')).map(e => (`--${e[0]}: ${e[1]}`)).join(';')
    str += '}'

    return str
}

const App = ({ onInited }) => {
    const [leagues, setLeagues] = useState([])
    const [activeLeague, setActiveLeague] = useState(null)
    const [tournament, setTournament] = useState(null)
    const [content, setContent] = useState('calendar')
    const [theme, setTheme] = useState({})
    const [layout, setLayout] = useState({})
    const [config, setConfig] = useState(null)
    const [manifest, setManifest] = useState(null)
    const [styleStr, setStyleStr] = useState(null)
    const [item, setItem] = useState(null)
    const [history, setHistory] = useState([])
    const [deferredPrompt, setDeferredPrompt] = useState(null)
    const [shownIosWorkaround, setShownIosWorkaround] = useState(false)
    const [device, setDevice] = useState({})
    const [offline, setOffline] = useState(false)

    const splideRef = useRef(null)
    const networkState = useRef(true)

    const initFlow = () => {
        axios.get(`${ENGINE}init`)
            .then(lgs => {
                if(lgs.data) {
                    setLeagues(lgs.data.leagues)
                    setActiveLeague(lgs.data.leagues[0])
                    setTournament(lgs.data.leagues[0].tournaments[0]._id)
                    setTheme({rainbow: {palette: lgs.data.theme}})
                    setLayout(lgs.data.layout)
                    setConfig(lgs.data.config)
                    setManifest(lgs.data.manifest)
                    setStyleStr(renderStyleStr(lgs.data.theme))
                    onInited(lgs.data)
                }
            }).catch(e => {
                setTimeout(() => {
                    console.log('re-initializing...')
                    initFlow()
                }, 3000)
            })
    }

    useEffect(() => {
        if(!networkState.current) {
            console.log('Listening for network recovery...')
            window.addEventListener('online', e => {
                networkState.current = true
                setOffline(false)
                window.removeEventListener('online', () => null)
            })
        }
    }, [networkState.current])

    useEffect(() => {
        window.addEventListener('offline', e => {
            console.log(networkState.current)
            if(networkState && networkState.current) {
                networkState.current = false
                setOffline(true)
            }
        })

        try {
            bridge.subscribe((e) => {
                if (e && e.detail && e.detail.type){
                    console.log('subscribe details', e.detail)
                }
            })

            if (bridge.supports("VKWebAppInit")) {
                bridge.send("VKWebAppInit", {})
            }

            if (bridge.supports("VKWebAppGetUserInfo")) {
                bridge.send("VKWebAppGetUserInfo")
            }
        } catch(e) {
            console.log('No VK environment. Skipping bridge...')
        }

        setDevice(uaparser.getResult())
        initFlow()
    }, [])

    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)

    useEffect(() => {
        if(activeLeague && activeLeague.tournaments && activeLeague.tournaments.length) {
            setTournament(activeLeague.tournaments[0]._id)
            setContent('results')
        }

        setTimeout(() => {
            if(manifest && manifest.name && device && device.os && !window.location.href.includes('localhost')) {
                if(device.os.name === 'Android') {
                    if(window.deferredPrompt) {
                        setDeferredPrompt(true)
                    }
                } else {
                    // Checks if should display install popup notification:
                    if(isIos() && !isInStandaloneMode() && !shownIosWorkaround) {
                        setDeferredPrompt(true)
                        setShownIosWorkaround(true)
                    }
                }
            }
        }, 1000)
    }, [activeLeague])

    const updItem = upd => {
        if(item && !history.find(elem => item.scope === elem.scope && item._id === elem._id)) {
            const hst = [item].concat(history)
            setHistory(hst.slice(0, 4))
        }

        if(!item) {
            setHistory([])
        }

        setItem(upd)
    }

    const Specified = contentTypes[content] ? contentTypes[content].component : null
    const Item = item && itemTypes[item.scope] ? itemTypes[item.scope] : null

    const isMobile = device && device.device && device.device.type === 'mobile'
    const activeTournament = activeLeague && activeLeague.tournaments && tournament ? activeLeague.tournaments.find(t => t._id === tournament) : null
    const sponsorship = activeTournament ? activeTournament.sponsorship || null : null

    return  <Application theme={theme}>
                <div className={'offline-prompt'+(offline ? ' active' : '')}>
                    <p>Ваш браузер оффлайн. Проверьте подклоючение к интернет</p>
                    <Button onClick={() => setOffline(false)} variant='brand' size='small'>Ок, понятно</Button>
                </div>

                <div className={'app'+(offline ? ' offline' : '')}>
                    <Helmet>
                        {config ? <title>{config.title}</title> : null}
                        {styleStr ? <style>{styleStr}</style> : null}
                        {config && config.appleIcon ? <link rel='apple-touch-icon' sizes='180x180' href={config.appleIcon} /> : null}
                        {config && config.favicon ? <link rel="icon" type="image/png" href={config.favicon} /> : null}
                    </Helmet>

                    <div className='splash-loader'>
                        <img src="/ball.png" />
                    </div>

                    <div className={`brand${item && (isMobile || item.scope === 'team') ? ' translated' : ''} ${layout ? layout.emblemClassName || '' : ''}`}>
                        <img src={config ? config.logo : ''} />
                    </div>

                    {deferredPrompt ? (
                        <Install
                            manifest={manifest}
                            prompt={deferredPrompt}
                            onOutcome={() => setDeferredPrompt(null)}
                            isIos={isIos()}
                        />
                    ) : null}

                    {leagues && leagues.length ? (
                        <div className={'container utility'+(item ? ' faded' : '')}>
                            <Splide
                                ref={splideRef}
                                options={{
                                    //type: leagues.length > 1 ? 'loop' : null,
                                    pagination: false,
                                    autoWidth: true,
                                    arrows: false,
                                    gap: 0,
                                    interval: 4500,
                                    perMove: 1,
                                    updateOnMove: true
                                }}
                                onActive={(e, i) => {
                                    if(leagues.length && leagues[i.index]) {
                                        setActiveLeague({...leagues[i.index]})
                                    }
                                }}
                                className='leagues'
                            >
                                {leagues.map((l, idx) => (
                                    <SplideSlide
                                        className={`league-link${activeLeague && activeLeague._id === l._id ? ' active' : ''} ${layout && layout.leagueLinkClassName ? layout.leagueLinkClassName : ''}`}
                                        key={l._id}
                                        onClick={() => splideRef.current.go(idx)}
                                    >{l.name}</SplideSlide>
                                ))}
                            </Splide>

                            <Pills
                                data={activeLeague ? activeLeague.tournaments : []}
                                selected={tournament}
                                onSelect={i => setTournament(i)}
                                isMobile={isMobile}
                            />
                        </div>
                    ) : null}

                    <div className={'content'+(item ? ' withItem' : '')+(sponsorship ? ' withSponsor' : '')}>
                        {item ? history && history.length ? (
                            <ButtonMenu
                                label='назад'
                                icon={<i className='icons'>chevron_left</i>}
                                className='item-back'
                                buttonVariant='neutral'
                                //buttonSize='small'
                            >
                                {history.map(h => (
                                    <MenuItem
                                        label={(h.scope === 'team' ? '⚽️ ' : h.scope === 'player' ? '👦🏻' : '')+h.name}
                                        onClick={() => updItem(h)}
                                    />
                                ))}
                                <MenuItem label='🏆 В начало' onClick={() => updItem(null)} />
                            </ButtonMenu>
                        ) : <Button
                            variant='neutral'
                            className='item-back'
                            size='sm'
                            onClick={() => updItem(null)}
                        >
                            <i className='icons'>chevron_left</i>назад
                        </Button> : null}

                        <div className={'container'+(item ? ' faded' : '')}>
                            <ContainerNav
                                scope='tournament'
                                content={content}
                                setContent={setContent}
                            />
                            <CustomScrollbars autoHeight autoHeightMin={isMobile ? sponsorship ? 'calc(100vh - (210px + 1em))' : 'calc(100vh - (190px + 1em))' : sponsorship ? 'calc(100vh - 350px)' : 'calc(100vh - 310px)'} autoHide>
                                {Specified ? (
                                    <Specified
                                        tournament={tournament}
                                        {...contentTypes[content].props}
                                        setItem={updItem}
                                    />
                                ) : null}
                            </CustomScrollbars>
                        </div>

                        <div className={'container'+(!item ? ' faded' : '')}>
                            {item && itemTypes[item.scope] ? <Item {...item} setItem={updItem} isMobile={isMobile} sponsorship={sponsorship} /> : null}
                        </div>
                    </div>

                    {sponsorship ? (
                        <div className='sponsorship'>
                            <label>генеральный партнёр лиги</label>
                            <img src={sponsorship.banner} onClick={() => window.open(sponsorship.link)} />
                        </div>
                    ) : config && config.promoMode ? (
                        <Promo promo={config.promo} promoMode={config.promoMode} />
                    ) : (
                        <div className='promo'>
                            <img src={config ? config.promo : ''} />
                        </div>
                    )}
                </div>

                {config && config.ym ? (
                    <YMInitializer
                        accounts={[config.ym]}
                        version='2'
                        options={{
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: true,
                            trackHash: true,
                        }}
                    />
                ) : null}
            </Application>
}

export default App
