import React, { useState, useRef, useLayoutEffect } from 'react'

const Broadcast = ({ data }) => {
    const [dimension, setDimension] = useState(null)

    useLayoutEffect(() => {
        if(containerRef) {
            setDimension({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetWidth/1.77
            })
        }
    }, [])

    const containerRef = useRef(null)

    return  <div className='broadcast' ref={containerRef} style={dimension || {}}>
                {data && data.source && dimension ? (
                    <iframe
                        src={`https://vk.com/video_ext.php?oid=${data.oid}&id=${data.id}&hash=${data.hash.trim()}&hd=1`}
                        width={dimension.width}
                        height={dimension.height}
                        allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                ) : null}
            </div>
}

export default Broadcast
