import React,{ useState } from 'react'

const Roster = ({ list, setItem }) => {
    return  <div className='roster-list'>
                {list ? list.players.map(p => (
                    <div className='roster-item' onClick={() => setItem({scope: 'player', _id: p._id, name: p.name+' '+p.surname})}>
                        <div className='num'>{p.num}</div>
                        <div className='name'>{p.name} {p.surname}</div>
                    </div>
                )) : []}
            </div>
}

const Rosters = ({ data, homeId, awayId, setItem }) => {
    const home = data.find(r => r.teamId === homeId)
    const away = data.find(r => r.teamId === awayId)

    return  <div className='rosters'>
                <Roster list={home} setItem={setItem} />
                <Roster list={away} setItem={setItem} />
            </div>
}

export default Rosters
