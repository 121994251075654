import React, { useState, useEffect } from 'react'

import { Button } from '@ui'

import moment from 'moment'
import axios from 'axios'
import { ENGINE }  from '@env'

import Loader from '@blocks/Loader'
import NonIdealState from '@blocks/NonIdealState'
import MatchCard from '@blocks/MatchCard'

import './style.scss'

const Calendar = ({ tournament, recent, setItem }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingAll, setLoadingAll] = useState(false)
    const [loadedAll, setLoadedAll] = useState(false)

    useEffect(() => {
        if(tournament) {
            setLoading(true)
            setLoadedAll(false)
            axios.get(`${ENGINE}calendar/${tournament}?recent=${recent ? 'true' : 'false'}`)
                .then(cal => {
                    setData(cal.data)
                    setLoading(false)
                })
        }
    }, [tournament, recent])

    const loadAll = () => {
        setLoadingAll(true)
        axios.get(`${ENGINE}calendar/${tournament}?recent=${recent ? 'true' : 'false'}&full=true`)
            .then(cal => {
                setData(cal.data)
                setLoadingAll(false)
                setLoadedAll(true)
            })
    }

    return  <div className='calendar'>
                <Loader active={loading} />
                <div className='calendar-body'>
                    {data ? data.length ? data.map(sect => {
                        const isGroupedByDate = /\d{2}-\d{2}-\d{2}/.test(sect.date)

                        return  <div className='calendar-section' key={sect.date}>
                                    <div className={'calendar-section_top'+(!isGroupedByDate ? ' muted' : '')}>{isGroupedByDate ? moment(sect.date, 'YY-MM-DD').format('dddd, D MMMM') : sect.date}</div>
                                    <div className='calendar-section_list'>
                                        {sect.list.map(match => (
                                            <MatchCard
                                                key={match._id}
                                                match={match}
                                                setItem={setItem}
                                                isGroupedByDate={isGroupedByDate}
                                            />
                                        ))}
                                    </div>
                                </div>
                    }) : !loading ? (
                        <NonIdealState
                            icon={recent ? 'sports_soccer' : 'calendar_today'}
                            title=''
                            description={recent ? 'нет результатов за последнюю неделю' : 'на ближайшую неделю матчи не запланированы'}
                        />
                    ) : null : null}
                </div>
                {!loadedAll ? <Button
                    className='content-load-more'
                    onClick={() => loadAll()}
                    variant='base'
                >{loadingAll ? [
                    <span className='icons spinned' style={{marginRight: 6}}>sports_soccer</span>,
                    'загрузка'
                ] : 'Показать все'}</Button> : null}
            </div>
}

export default Calendar
