import React, { useState, useRef } from 'react'

import { Button, ButtonMenu, MenuItem } from '@ui'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import './style.scss'

const Pills = ({ data, selected, onSelect, isMobile }) => {
    const selectedFull = data ? data.find(t => t._id === selected) : null

    const splideRef = useRef(null)

    return  <div className={`pills${isMobile ? ' dropdown' : ''}`}>
                {!isMobile ? [
                    <Splide
                        ref={splideRef}
                        options={{
                            //type: leagues.length > 1 ? 'loop' : null,
                            pagination: false,
                            autoWidth: true,
                            arrows: false,
                            gap: 0,
                            interval: 4500,
                            perMove: 1,
                            updateOnMove: true
                        }}
                        onActive={(e, i) => {
                            // if(leagues.length && leagues[i.index]) {
                            //     setActiveLeague({...leagues[i.index]})
                            // }
                        }}
                    >
                        {data.map((item, idx) => (
                            <SplideSlide
                                onClick={() => splideRef.current.go(idx)}
                                key={item._id}
                            >
                                <Button
                                    label={item.name}
                                    size='small'
                                    variant={selected === item._id ? 'brand' : 'neutral'}
                                    onClick={() => onSelect(item._id)}
                                />
                            </SplideSlide>
                        ))}
                    </Splide>,
                    data && data.length > 4 ? <span className='pills swipe-indicator icons'>swipe</span> : null
                ] : (
                    <ButtonMenu
                        label={selectedFull ? selectedFull.name : ''}
                        buttonSize='small'
                        buttonVariant='brand'
                        icon={<i className='icons'>expand_circle_down</i>}
                        iconPosition='right'
                    >
                        {data.map(tour => (
                            <MenuItem label={tour.name} onClick={() => onSelect(tour._id)} />
                        ))}
                    </ButtonMenu>
                )}
            </div>
}

export default Pills
