import React, { useState } from 'react'

import { Button } from '@ui'
import './style.scss'

const scopes = {
    tournament: [
        {value: 'calendar', label: 'Расписание', icon: 'calendar_today'},
        {value: 'results', label: 'Результаты', icon: 'sports_soccer'},
        {value: 'standings', label: 'Таблица', icon: 'emoji_events'},
        {value: 'rankings', label: 'TOP-20', icon: 'grade'}
    ],
    match: [
        {value: 'info', label: 'Инфо', icon: 'info'},
        {value: 'broadcast', label: 'Трансляция', icon: 'live_tv'},
        {value: 'events', label: 'События', icon: 'sports_soccer'},
        {value: 'rosters', label: 'Составы', icon: 'grade'}
    ],
    rankings: [
        {label: 'Бомбардиры', value: 'gs'},
        {label: 'Ассистенты', value: 'a'},
        {label: 'Гол+Пас', value: 'gas'},
        {label: 'Карточки', value: 'cards'}
    ],
    team: [
        {label: 'Результаты', value: 'recent', icon: 'sports_soccer'},
        {label: 'Ближайшие', value: 'upcoming', icon: 'calendar_today'},
        {label: 'Состав', value: 'squads', icon: 'group'}
    ],
    tables: [
        {label: 'Стандартная', value: 'compact', icon: 'emoji_events'},
        {label: 'Шахматка', value: 'chess', icon: 'apps'}
    ]
}

const ContainerNav = ({ scope, content, setContent, isNested, filter }) => {
    return  <div className={`container-nav${isNested ? ' nested' : ''}`}>
                {scopes[scope].filter(btn => btn.value !== filter).map(btn => (
                    <Button
                        key={btn.value}
                        size='small'
                        variant='base'
                        className={content === btn.value ? 'active' : ''}
                        onClick={() => setContent(btn.value)}
                    >
                        <span className='icons'>{btn.icon}</span>
                        {btn.label}
                    </Button>
                ))}
            </div>
}

export default ContainerNav
