import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PWA_VERSION } from '@env';

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const rootElem = document.getElementById('root');
const bodyElem = document.getElementById('body');
const root = ReactDOM.createRoot(rootElem);

root.render(
    <App onInited={initData => {
        console.log('Inited evt fired')
        setTimeout(() => {
            bodyElem.classList.add('mounted')
        }, 1000)

        if(initData.manifest) {
            const filledManifest = {
                    ...initData.manifest,
                version: PWA_VERSION,
                start_url: process.env.NODE_ENV === 'production' ? initData.manifest.start_url : 'http://localhost:3000',
                display: 'fullscreen',
                orientation: 'portrait'
            }

            const str = JSON.stringify(filledManifest)
            const blob = new Blob([str], {type: 'application/json'})
            const manifestUrl = URL.createObjectURL(blob)
            document.querySelector('#manifest-slot').setAttribute('href', manifestUrl)
        }
    }} />
);

serviceWorkerRegistration.register().then(reg => {
    console.log('Resolved register')
    console.log('Adding listener')
    window.addEventListener('beforeinstallprompt', evt => {
        window.deferredPrompt = evt
        console.log('BEFORE IP Fired. Need to show dialog...')
    })
})
