import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { ENGINE } from '@env'

import Loader from '@blocks/Loader'
import ContainerNav from '@blocks/ContainerNav'

import './style.scss'

const commonFields = [
    {path: 'pos', head: ''},
    {path: 'name', head: ''},
    {path: 'team', head: ''}
]

const fields = {
    gs: [
        {path: 'gs', head: 'Голы'},
        {path: 'penalty', head: 'Пен'}
    ],
    a: [
        {path: 'a', head: 'Ассисты'}
    ],
    gas: [
        {path: 'gas', head: 'Г+А'},
        {path: 'gs', head: 'Г'},
        {path: 'a', head: 'А'}
    ],
    cards: [
        {path: 'cards', head: 'Всего'},
        {path: 'yc', head: 'ЖК'},
        {path: 'rc', head: 'КК'}
    ]
}

const Table = ({ data, fields, setItem }) => {
    return  <div className='rankings-table'>
                <div className='rankings-row head-row'>
                    <div className='common'>
                        <div className='pos'><i className='icons'>grade</i></div>
                        <div className='name'></div>
                    </div>
                    <div className='spec'>
                        <div className='pld'>И</div>
                        {fields.map(f => (
                            <div className={f.path}>{f.head}</div>
                        ))}
                    </div>
                </div>
                {data.map(p => (
                    <div className='rankings-row' onClick={() => setItem({scope: 'player', _id: p._id, name: p.name})}>
                        <div className='common'>
                            <div className='pos'>{p.pos}</div>
                            <div className='name'>
                                <div>{p.name}</div>
                                <div className='team'>{p.team}</div>
                            </div>
                        </div>
                        <div className='spec'>
                            <div className='pld'>{p.data.pld}</div>
                            {fields.map(f => (
                                <div className={f.path}>{p.data[f.path]}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
}

const Rankings = ({ tournament, setItem }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [scope, setScope] = useState('gs')

    useEffect(() => {
        if(tournament) {
            setLoading(true)
            axios.get(`${ENGINE}rankings/${tournament}`)
                .then(rnks => {
                    setData(rnks.data)
                    setLoading(false)
                })
        }
    }, [tournament])

    return  <div className='rankings'>
                <Loader active={loading} />
                <ContainerNav
                    content={scope}
                    setContent={setScope}
                    scope='rankings'
                    isNested={true}
                />
                {data ? <Table
                    data={data[scope].map((r, i) => ({...r, pos: i+1}))}
                    fields={fields[scope]}
                    setItem={setItem}
                /> : null}
            </div>
}

export default Rankings
