import React, { useState } from 'react'

const mergeEvents = obj => {
    return Object.entries(obj).reduce((acc, t) => {
        for(let type in t[1]) {
            const evs = t[1][type]
            for(let e of evs) {
                acc.push({
                    ...e,
                    side: t[0],
                    type: type,
                    player: e.player ? {
                        ...e.player,
                        name: e.player.name.split(' ').reverse().join(' ')
                    } : null,
                    assistant: e.assistant ? {
                        ...e.assistant,
                        name: e.assistant.name.split(' ').reverse().join(' ')
                    } : null,
                    owngoal: e.owngoal ? 'owngoal' : null,
                    penalty: e.penalty ? 'penalty' : null,
                    missedPenalty: e.missedPenalty ? 'missedPenalty' : null
                })
            }
        }
        return acc
    }, []).sort((a, b) => (parseInt(a.minute) - parseInt(b.minute)))
}

const Events = ({ data, setItem }) => {
    const merged = mergeEvents(data.find(evs => evs.type === 'events') ? data.find(evs => evs.type === 'events').data : [])

    return  <div className='events'>
                {merged.map(e => (
                    <div className={`event ${e.side}`} key={e.uuid}>
                        <div className='info'>
                            <div>{e.minute}`</div>
                            <div className={`icon ${e.penalty || e.missedPenalty || e.owngoal || e.type}`}></div>
                        </div>
                        <div className='names' onClick={() => setItem({scope: 'player', _id: e.player._id, name: e.player.name+' '+e.player.surname})}>
                            <div>{e.player.name} {e.player.surname}</div>
                            {e.assistant ? (
                                <div className='secondary'>{e.assistant.name} {e.assistant.surname}</div>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
}

export default Events
